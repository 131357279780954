<template>
  <div>
    <Title>设计类-必要分包</Title>
    <Padding />
    <Table :isNecessary="1" :list="necessaryList" />
    <Title>设计类-非必要分包</Title>
    <Padding />
    <Table :isNecessary="0" :list="list" />
    <Title>总承包类分包</Title>
    <Padding />
    <Table :isNecessary="2" :list="otherList" />
    <Title>项目净额</Title>
    <Padding />
    <div class="total">
      <span class="money-color">
        <Money :money="project.projectPureAmount" />
      </span>
      <span class="unit">万元</span>
    </div>
    <Padding />
    <Padding />
    <Padding />
    <Padding />

    <a-space>
      <a-button @click="prev">上一步</a-button>
      <a-button type="primary" @click="next"> 下一步 </a-button>
    </a-space>

    <Explain content="请查看项目分包详情，并完成项目阶段选择" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Title from "../title/index.vue";
import Explain from "../explain";
import Table from "./table";
export default {
  components: {
    Explain,
    Table,
    Title,
  },

  data() {
    return {
      necessaryList: [], // 必要分包
      list: [], // 非必要
      otherList: [],
    };
  },

  computed: {
    ...mapState("projectConfig", ["project"]),
  },

  watch: {
    project(newValue) {
      if (newValue) {
        if (Array.isArray(newValue.subPackages)) {
          const necessaryList = [];
          const list = [];
          const otherList = [];
          newValue.subPackages.forEach((item) => {
            if (item.isneeded === 1) {
              necessaryList.push({
                ...item,
                planAmount: item.planAmount / 10000,
              });
            } else if (item.isneeded === 0) {
              list.push({
                ...item,
                planAmount: item.planAmount / 10000,
              });
            } else if (item.isneeded === 2) {
              otherList.push({
                ...item,
                planAmount: item.planAmount / 10000,
              });
            }
          });

          this.necessaryList = necessaryList;
          this.list = list;
          this.otherList = otherList;
        }
      }
    },
  },

  methods: {
    ...mapMutations("projectConfig", ["setStep"]),

    prev() {
      this.setStep(0);
    },

    next() {
      this.setStep(2);
    },
  },
};
</script>


<style lang='less' scoped>
.total {
  margin-left: 18px;
  font-size: 24px;
  .unit {
    font-size: 12px;
    padding-left: 8px;
  }
}
</style>

