<template>
    <div class="explain">
        <a-divider />
        <div class="title">说明</div>
        <div>{{content}}</div>
    </div>
</template>

<script>
export default {
    props: ['content']
}
</script>

<style lang="less" scoped>
.explain {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    .title {
        font-size: 16px;
        margin-bottom: 15px;
    }
}
</style>