<template>
  <a-result :status="status" :title="title" :sub-title="subTitle">
    <template #extra>
      <a-space>
        <a-button key="prev" :disabled="hasDisabled" @click="prev">
          上一步
        </a-button>
        <a-button
          key="start"
          type="primary"
          :loading="loading"
          :disabled="hasDisabled"
          @click="save"
        >
          保存
        </a-button>
        <a-button key="done" @click="done"> 关闭 </a-button>
      </a-space>
    </template>
  </a-result>
</template>

<script>
import { config } from "@/api/project";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      hasDisabled: false,
      status: "info",
      title: "请点击保存",
      subTitle: "",
    };
  },

  computed: {
    ...mapState("projectConfig", ["project"]),
  },

  methods: {
    ...mapMutations("projectConfig", ["setProject", "setStep"]),

    done() {
      this.setProject({});
      this.setStep(0);
      this.$emit("done");
    },

    save() {
      const that = this;
      this.$confirm({
        title: "保存配置后无法再次修改，是否继续？",
        onOk() {
          that.loading = true;
          config({
            ...that.project,
          })
            .then(() => {
              that.done();
            })
            .finally(() => {
              that.loading = false;
            });
        },
      });
    },

    prev() {
      this.setStep(2);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}
.content {
  width: 1000px;
  margin: 0 auto;
}
.steps {
  margin-bottom: 40px;
}
</style>