<template>
  <div>
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :colon="false">
      <a-form-item label="合同名称">
        <div>
          {{ project.name }}
        </div>
      </a-form-item>
      <a-form-item label="设计编号">
        <div v-if="project.designCode">
          {{ project.designCode }}
        </div>
        <div v-else>--</div>
      </a-form-item>
      <a-form-item label="合同金额">
        <div v-if="project.projectAmount">
          <Money :money="project.projectAmount" />
          <span> 万元</span>
        </div>
        <div v-else>--</div>
      </a-form-item>
      <a-form-item label="责任部门">
        <div v-if="project.contract && project.contract.productionUnitNameUnique">
          {{ project.contract.productionUnitNameUnique }}
        </div>
        <div v-else>--</div>
      </a-form-item>

      <a-form-item label="所属区域">
        <div v-if="project.contract && project.contract.areaName">
          <span>{{ project.contract.areaName }}</span>
          <span v-if="project.contract.subAreaName">/</span>
          <span>{{ project.contract.subAreaName }}</span>
          <span v-if="project.contract.l3AreaName">/</span>
          <span>{{ project.contract.l3AreaName }}</span>
        </div>
        <div v-else>--</div>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" @click="next"> 下一步 </a-button>
      </a-form-item>
    </a-form>
    <Explain content="请查看项目相关信息，并完成项目阶段选择" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Explain from "../explain";
export default {
  components: {
    Explain,
  },

  computed: {
    ...mapState("projectConfig", ["project"]),
  },

  methods: {
    ...mapMutations("projectConfig", ["setStep"]),

    next() {
      this.setStep(1);
    },
  },
};
</script>

