<template>
  <div class="title"><slot /></div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  position: relative;
  left: 18px;
}
.title::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -15px;
  width: 4px;
  height: 18px;
  background: #1890ff;
}
</style>