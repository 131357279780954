<template>
  <div>
    <Pane />

    <div class="container">
      <div class="content">
        <a-steps :current="step" class="steps">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>

        <div v-show="step === 0">
          <BaseForm />
        </div>
        <div v-show="step === 1">
          <SubPackage />
        </div>
        <div v-show="step === 2">
          <AdvancedForm />
        </div>

        <div v-show="step === 3">
          <Done @done="done" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchDetail } from "@/api/project";

import BaseForm from "./base-form";
import SubPackage from "./subpackage";
import AdvancedForm from "./advanced-form";
import Done from "./done";

import { mapMutations, mapState } from "vuex";
export default {
  name: "progressConfig",

  components: {
    BaseForm,
    SubPackage,
    AdvancedForm,
    Done,
  },
  data() {
    return {
      steps: [
        { title: "项目基本信息" },
        { title: "项目分包详情" },
        { title: "项目阶段选择" },
        { title: "完成" },
      ],
    };
  },

  computed: {
    ...mapState("projectConfig", ["project", "step"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    // 进入页面，传入了新的 id，请求新的项目详情
    if (id && this.project.id !== id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.setStep(0);
        this.setProject(res);
      });
    }
  },

  methods: {
    ...mapMutations("projectConfig", ["setProject", "setStep"]),

    done() {
      this.$close(this.$route.path);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.steps {
  margin-bottom: 40px;
}
</style>