<template>
  <div style="margin-bottom: 24px">
    <a-table :data-source="list" bordered :pagination="false">
      <a-table-column title="分包名称" data-index="name" />
      <a-table-column title="分包类型">
        <template slot-scope="text">
          <div class="left">
            <DataDictFinder
              v-if="isNecessary"
              dictType="subType"
              :dictValue="text.type"
            />
            <DataDictFinder
              v-else
              dictType="notNeedSubType"
              :dictValue="text.type"
            />
            <template v-if="text.major">
              <span> - </span>
              <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
            </template>
          </div>
        </template>
      </a-table-column>
      <a-table-column
        title="公司"
        data-index="companyName"
        width="240px"
        ellipsis="true"
      ></a-table-column>
      <a-table-column
        title="预计分包金额(万元)"
        data-index="planAmount"
        align="right"
      ></a-table-column>
      <a-table-column title="分包状态" data-index="status" align="center">
        <template slot-scope="status">
          <div>
            <DataDictFinder
              dictType="subpackageStatus"
              :dictValue="status"
              iconType="badge"
              badgeType="color"
            />
          </div>
        </template>
      </a-table-column>
      <a-table-column
        title="备注"
        data-index="remarks"
        ellipsis="true"
      ></a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    isNecessary: Boolean,
    list: Array,
  },
};
</script>

<style lang="less" scoped>
</style>

