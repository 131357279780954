<template>
  <div>
    <div>
      <div class="title">项目类型</div>
    </div>

    <!-- v-model="selectedType" -->
    <a-radio-group :value="project.projectType" @change="onTypeChange">
      <a-radio v-for="item in typeList" :key="item.value" :value="item.value">{{
              item.name
              }}</a-radio>
    </a-radio-group>

    <a-divider />
    <div>
      <div class="title">项目阶段</div>
    </div>
    <a-select :value="project.projectPhase" @change="onPhaseChange" style="width: 400px; margin-bottom: 24px">
      <a-select-option v-for="item in showPhaseList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>

    <div v-if="selectedPhaseList.length > 0">
      <span v-for="(item, index) in selectedPhaseList" :key="index">
        <a-button-group>
          <a-button type="primary">
            {{ item.name }}
          </a-button>
          <a-button>
            {{ item.percent }}
          </a-button>
        </a-button-group>
        <a-icon type="arrow-right" class="arrow" v-if="index !== selectedPhaseList.length - 1" />
      </span>
    </div>

    <div class="buttons">
      <a-space>
        <a-button @click="prev">上一步</a-button>
        <a-button @click="next" type="primary">下一步</a-button>
      </a-space>
    </div>

    <Explain content="请完成项目阶段选择" />
  </div>
</template>

<script>
import Explain from "../explain";
import { fetchTypeList } from "@/api/project";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {
    Explain,
  },

  data() {
    return {
      phaseList: [],
    };
  },

  watch: {
    "project.projectType"(newValue) {
      if (newValue) {
        fetchTypeList({
          type: newValue,
        }).then((res) => {
          if (Array.isArray(res)) {
            this.phaseList = res;
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("projectType");
    },

    ...mapState("projectConfig", ["project"]),

    // 如果合同还未签订，只让用户选择方案
    showPhaseList() {
      const { contract } = this.project;
      const { contractSignStatus: status } = contract || {};
      if (contract && contract.contractSignStatus) {
        // 未签订
        if (status === "unsigned") {
          return this.phaseList.filter((item) => item.name === "方案");
        } else {
          return this.phaseList;
        }
      } else {
        return this.phaseList;
      }
    },

    // 用户选择了阶段后，渲染阶段的详细信息
    selectedPhaseList() {
      if (this.project.projectPhase) {
        const obj = this.phaseList.find(
          (item) => item.id === this.project.projectPhase
        );
        return obj && Array.isArray(obj.projectPhaseList)
          ? [...obj.projectPhaseList].sort((x, y) => {
              const xStep = parseInt(x.step);
              const yStep = parseInt(y.step);
              if (xStep < yStep) {
                return -1;
              } else if (xStep > yStep) {
                return 1;
              } else {
                return 0;
              }
            })
          : [];
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapMutations("projectConfig", ["setProject", "setStep"]),

    // 改变项目类型
    onTypeChange(e) {
      // 当用户重新选择类型的时候，重置已选中的阶段和要刷新的阶段列表
      this.setProject({
        ...this.project,
        projectType: e.target.value,
        projectPhase: null,
      });
      this.phaseList = [];
    },
    // 改变项目阶段
    onPhaseChange(value) {
      this.setProject({
        ...this.project,
        projectPhase: value,
      });
    },

    prev() {
      this.setStep(1);
    },

    next() {
      if (!this.project.projectType) {
        this.$message.error("请选择项目类型！");
        return;
      }

      if (!this.project.projectPhase) {
        this.$message.error("请选择项目阶段！");
        return;
      }

      this.setStep(3);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
  padding-bottom: 8px;
  display: inline-block;
  margin-bottom: 24px;
  font-weight: 500;
}

.arrow {
  margin: 0 12px;
  color: #d9d9d9;
}

.buttons {
  margin-top: 130px;
}
</style>